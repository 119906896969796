// Library imports
import { configureStore } from '@reduxjs/toolkit';

// Slice imports
import appSlice from 'STORE/appSlice';
import teamsMeetingSlice from 'STORE/teamsMeetingSlice';
import userSlice from 'STORE/userSlice';
import captionsSlice from 'STORE/captionsSlice';

const store = configureStore({
  reducer: {
    app: appSlice,
    user: userSlice,
    captions: captionsSlice,
    teamsMeeting: teamsMeetingSlice,
  },
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
