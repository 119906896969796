// Libraries Import
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Flex, Dropdown, Loader } from '@fluentui/react-northstar';

// Other imports
import { LANGUAGE_PREFIX, AUA_LANGUAGE_RESOURCE } from 'CONSTANTS/auaConstants';

import { userSelector } from 'STORE/userSlice';

import { IDropdownLanguage, ISpokenLanguage } from 'UTILS/auaInterface';

import './LanguageDropdown.css';

const LanguageDropdown = ({ updateLanguage, spokenLanguages, loading }: IDropdownLanguage) => {
  const { t } = useTranslation(AUA_LANGUAGE_RESOURCE, LANGUAGE_PREFIX.COMMON);

  // restructuring the languge according to Dropdown component
  const structureLanguages = (spokenLanguages: ISpokenLanguage[]) => {
    return spokenLanguages?.map((spokenLanguage: ISpokenLanguage) => {
      if (!spokenLanguage?.code) return;
      return {
        header: `${spokenLanguage.name} - ${spokenLanguage.country}`,
        value: spokenLanguage.code,
        key: spokenLanguage.code,
      };
    });
  };

  // restructuring languages object
  const languages = useMemo(() => structureLanguages(spokenLanguages), [spokenLanguages]);

  // User selected spoken langauge
  const selectedLanguage = useSelector(userSelector.selectedSpokenLanguages);

  return (
    <Flex hAlign="center" className="language-selection">
      {loading && <Loader size="small" className="language-loader" />}
      <Dropdown
        className="language-dropdown"
        items={languages}
        placeholder={`${t('SELECT_LANGUAGE_DROPDOWN')}`}
        onChange={(e, languageSelected: any) => {
          // if previously selected language code and new selected language is same then return
          if (languageSelected.value.key === selectedLanguage?.code) return;
          // if previously selected language code and new selected language is different
          // then return new language object and update language
          return spokenLanguages.filter(
            (spokenLanguage: ISpokenLanguage) =>
              spokenLanguage?.code === languageSelected.value.key && updateLanguage(spokenLanguage)
          );
        }}
        defaultValue={structureLanguages([selectedLanguage])}
        fluid
        inverted
      />
    </Flex>
  );
};

export default LanguageDropdown;
