// Libraries import
import { useSelector } from 'react-redux';
import { Button, CloseIcon, Flex, FlexItem, Text } from '@fluentui/react-northstar';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

// Other Import
import {
  APP_NAME,
  AUA_LANGUAGE_RESOURCE,
  CALL_ADAPTER_STATE,
  CALL_STATUS,
  CONNECTION_STATUS,
  COPYRIGHT_TEXT,
  LANGUAGE_PREFIX,
  MESSAGE_FORMAT,
  SERVER_EVENT,
  SNACKBAR_TYPE,
} from 'CONSTANTS/auaConstants';
import { API_CONTEXT_AUA } from 'CONSTANTS/apiConstants';

import Captions from 'FEATURES/captions/Captions';
import TeamsMeeting from 'FEATURES/TeamsMeeting/TeamsMeeting';

import useActions from 'HOOKS/useActions';
import useMessage from 'HOOKS/useMessage';

import { appActions } from 'STORE/appSlice';
import { teamsMeetingActions, teamsMeetingSelector } from 'STORE/teamsMeetingSlice';
import { userActions, userSelector } from 'STORE/userSlice';

import logger from 'SERVICES/logger';
import PubSubClient from 'SERVICES/PubSubClient';
import { putMeeting } from 'SERVICES/api';

import { IParicipantInfo } from 'UTILS/apiInterface';
import { IDashboardProps } from 'UTILS/auaInterface';

import './Dashboard.css';
import useSnackbar from 'HOOKS/useSnackbar';

const Dashboard = ({
  userName,
  accessToken,
  setLoadTeamsMeetingComponent,
  setSocketClientError,
}: IDashboardProps) => {
  // For translations
  const { t } = useTranslation(AUA_LANGUAGE_RESOURCE, LANGUAGE_PREFIX.COMMON);

  // Selectors
  const pubsubDetails = useSelector(userSelector.pubSubDetails);
  const userId = useSelector(userSelector.userId);
  const userObjectId = useSelector(userSelector.userObjectId);
  const meetingDetails = useSelector(teamsMeetingSelector.meetingDetails);
  const wsClient = useSelector(userSelector.wsClientObj);

  // Actions
  const { setWsClient, setSelectedSpokenLanguages } = useActions(userActions);
  const { setConnectionStatus } = useActions(appActions);
  const { setCallEnded } = useActions(teamsMeetingActions);

  // Custom hooks
  const { handleCaptions, handleWsMessage, handleCallStatus, handleCaptionerStatus } = useMessage();
  const showSnackbar = useSnackbar();

  // State
  const [loadCaptionsComponent, setLoadCaptionsComponent] = useState(false);
  const [callState, setCallState] = useState('');
  const [connectionSubId, setConnectionSubId] = useState<any>('');
  const [messageSubId, setMessageSubId] = useState<any>('');
  const [captionSubId, setCaptionSubId] = useState<any>('');
  const [captionerStatusSubId, setCaptionerStatusSubId] = useState<any>('');
  const [callStatusSubId, setCallStatusSubId] = useState<any>('');
  const [languageLoader, setLanguageLoader] = useState(false);
  const [showMicrophoneWarningDialog, setShowMicrophoneWarningDialog] = useState(false);

  // add online / offline listner
  const addConnectionListener = () => {
    logger.debug('[DASHBOARD] Adding connection listners');

    // offline listner
    window.addEventListener(CONNECTION_STATUS.OFFLINE, () => {
      setConnectionStatus(CONNECTION_STATUS.OFFLINE);
    });
    // online listner
    window.addEventListener(CONNECTION_STATUS.ONLINE, () => {
      setConnectionStatus(CONNECTION_STATUS.ONLINE);
    });
  };

  // remove online / offline listner
  const removeConnectionListener = () => {
    logger.debug('[DASHBOARD] Removing connection listners');
    window.removeEventListener(CONNECTION_STATUS.OFFLINE, () => {
      setConnectionStatus(CONNECTION_STATUS.OFFLINE);
    });
    window.removeEventListener(CONNECTION_STATUS.ONLINE, () => {
      setConnectionStatus(CONNECTION_STATUS.ONLINE);
    });
  };

  // Main useEffect
  useEffect(() => {
    if (!meetingDetails) return;

    // adding listners to check connection status
    addConnectionListener();
    // using distructor to remove listners
    return cleanup;
  }, []);

  // update captions component state
  const updateCaptionsComponent = (value: boolean) => {
    setLoadCaptionsComponent(value);
  };

  // cleanup function
  const cleanup = () => {
    // IIFE for cleanup
    (async () => {
      logger.debug('[DASHBOARD] Cleaning up Pubsub connections');
      try {
        // user disconnect message body
        const userDisconnectedMessage = {
          userId,
        };
        // sending dicosconnect message
        await wsClient?.sendMessageToGroup(
          meetingDetails?.meetingId,
          { userDisconnected: userDisconnectedMessage },
          MESSAGE_FORMAT.JSON
        );
        logger.debug('[DASHBOARD] Sending disconnect message');
        // unsubscribing the events
        await wsClient?.unsubscribe(PubSubClient.WS_EVENT.WS_CONNECTED, connectionSubId);
        await wsClient?.unsubscribe(PubSubClient.WS_EVENT.CAPTIONS, captionSubId);
        await wsClient?.unsubscribe(PubSubClient.WS_EVENT.WS_MESSAGE, messageSubId);
        await wsClient?.unsubscribe(PubSubClient.WS_EVENT.CALL_STATUS, callStatusSubId);
        await wsClient?.unsubscribe(PubSubClient.WS_EVENT.CAPTIONER_STATUS, captionerStatusSubId);
        // websocket client cleanup
        await wsClient?.cleanup();
        removeConnectionListener();
      } catch (error) {
        logger.error('Error while cleaning up pubsub connections', error);
      }
    })();
  };

  // spoken language change
  const spokenLanguageChange = async (language: any) => {
    logger.debug('Spoken language change:', language);
    // call put api to update language
    // send pubsub message to group

    try {
      logger.debug('[DASHBOARD] Changing language to: ', language);
      setLanguageLoader(true);
      // await updateLanguage(language);
      const updateData: IParicipantInfo[] = [];
      updateData.push({
        id: userId,
        spokenLanguageCode: language.code,
        type: API_CONTEXT_AUA,
        userObjectId,
      });

      await putMeeting({
        meetingId: meetingDetails.meetingId,
        participants: updateData,
      });

      // language change message body
      const userLanguageChangeMessage = {
        userId,
        spokenLanguageCode: language.code,
        userObjectId,
      };
      // sending message over WS
      await wsClient.sendMessageToGroup(
        meetingDetails.meetingId,
        { userSpokenLanguageChanged: userLanguageChangeMessage },
        MESSAGE_FORMAT.JSON
      );
      // storing selected language in store
      setSelectedSpokenLanguages(language);
      setLanguageLoader(false);
      showSnackbar({
        message: t('LANGUAGE_CHANGE_SUCCESS'),
        type: SNACKBAR_TYPE.SUCCESS,
      });
    } catch (error: any) {
      logger.error('Error while changing language', error);
      showSnackbar({
        message: t('LANGUAGE_CHANGE_ERROR'),
        type: SNACKBAR_TYPE.DANGER,
      });
      setLanguageLoader(false);
    }
  };

  // function to toggle microphone warning dialog
  const toggleMicrophoneWarningDialog = () => {
    setShowMicrophoneWarningDialog(!showMicrophoneWarningDialog);
  };

  // To handle state of call
  useEffect(() => {
    logger.debug('[DASHBOARD] Call state changes:', callState);

    try {
      if (callState === CALL_ADAPTER_STATE.CONNECTED) {
        logger.debug('Connected to meeting now we can create pubsub');
        (async () => {
          try {
            const webSocketClient = new PubSubClient();
            await webSocketClient.init(pubsubDetails?.url);
            // storing WS_CONNECTED event subscription id
            setConnectionSubId(
              // subscribing to WS_CONNECTED event
              webSocketClient.subscribe(PubSubClient.WS_EVENT.WS_CONNECTED, (eventData: any) => {
                logger.info(eventData);
                // WS_CONNECTED message body
                const userConnectedMessage = {
                  userId,
                  userObjectId,
                };
                // sending message to group about the successfull connection
                webSocketClient.sendMessageToGroup(
                  meetingDetails?.meetingId,
                  { userConnected: userConnectedMessage },
                  MESSAGE_FORMAT.JSON
                );
              })
            );
            // storing CAPTIONS event subscription id
            await setCaptionSubId(
              // subscribing to CAPTIONS event
              webSocketClient.subscribe(PubSubClient.WS_EVENT.CAPTIONS, (eventData: any) => {
                logger.debug('[DASHBOARD] CAPTIONS:', eventData);
                handleCaptions(eventData);
              })
            );
            // storing WS_MESSAGE event subscription id
            await setMessageSubId(
              // subscribing to WS_MESSAGE event
              webSocketClient.subscribe(PubSubClient.WS_EVENT.WS_MESSAGE, (eventData: any) => {
                logger.debug('[DASHBOARD] SERVER MESSAGE:', eventData);
                handleWsMessage(eventData);
              })
            );

            // storing CAPTIONER_STATUS event subscription id
            await setCaptionerStatusSubId(
              // subscribing to CAPTIONER_STATUS event
              webSocketClient.subscribe(
                PubSubClient.WS_EVENT.CAPTIONER_STATUS,
                (eventData: any) => {
                  logger.debug('[DASHBOARD] CAPTIONER STATUS:', eventData);
                  handleCaptionerStatus(eventData);
                }
              )
            );

            // storing CALL_STATUS event subscription id
            await setCallStatusSubId(
              // subscribing to CALL_STATUS event
              webSocketClient.subscribe(PubSubClient.WS_EVENT.CALL_STATUS, (eventData: any) => {
                logger.debug('[DASHBOARD] CALL STATUS:', eventData);
                handleCallStatus(eventData);
              })
            );

            // joining group
            await webSocketClient.joinGroup(meetingDetails.meetingId);

            // Getting status for captioner
            await webSocketClient.sendMessageToServer(
              SERVER_EVENT.getCaptionerStatus,
              {},
              MESSAGE_FORMAT.JSON
            );

            // storing socket client
            setWsClient(webSocketClient);
            setShowMicrophoneWarningDialog(true);
          } catch (error) {
            logger.error('unable to create client', error);
            // set Error True to render it on page
            setSocketClientError(true);
          }
        })();
      }

      if (callState === CALL_ADAPTER_STATE.CALL_ENDED || callState === CALL_STATUS.DISCONNECTING) {
        cleanup();
        setLoadTeamsMeetingComponent(false);
        setCallEnded(true);
        setShowMicrophoneWarningDialog(false);
      }
    } catch (error) {
      logger.debug('Error while handling call state', error);
    }
  }, [callState]);

  return (
    <Flex fill id="dashboard-component">
      <FlexItem grow={1}>
        <TeamsMeeting
          userName={userName}
          accessToken={accessToken}
          callState={callState}
          setLoadCaptionsComponent={updateCaptionsComponent}
          loadCaptionsComponent={loadCaptionsComponent}
          setCallState={setCallState}
          showMicrophoneWarningDialog={showMicrophoneWarningDialog}
          toggleMicrophoneWarningDialog={toggleMicrophoneWarningDialog}
        />
      </FlexItem>
      {loadCaptionsComponent && (
        <Flex className="captions-section" column>
          <Flex className="app-header" space="between">
            <Text size="large" weight="bold" content={APP_NAME} className="application-name" />
            <Button
              icon={<CloseIcon />}
              text
              iconOnly
              title="Close"
              onClick={() => setLoadCaptionsComponent(!loadCaptionsComponent)}
            />
          </Flex>
          <Flex fill className="live-captions">
            <Captions
              spokenLanguageChange={spokenLanguageChange}
              loading={languageLoader}
              loadCaptionsComponent={loadCaptionsComponent}
            />
          </Flex>
          <Flex hAlign="center" className="app-footer">
            <Text size="small" align="center" content={COPYRIGHT_TEXT} />
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};

export default Dashboard;
