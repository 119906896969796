// Libraries import
import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { Spinner } from '@fluentui/react';
import { Flex } from '@fluentui/react-northstar';
import {
  CallWithChatComposite,
  CustomCallControlButtonCallback,
  CustomCallControlButtonProps,
  CustomCallControlButtonCallbackArgs,
  darkTheme,
  useAzureCommunicationCallWithChatAdapter,
} from '@azure/communication-react';
import { AzureCommunicationTokenCredential } from '@azure/communication-common';

// Components import
import InMeetingDialog from 'COMPONENTS/InMeetingDialog/InMeetingDialog';

// Other imports
import {
  AUA_LANGUAGE_RESOURCE,
  CALL_ADAPTER_STATE,
  CALL_STATUS,
  FORM_FACTOR,
  LANGUAGE_PREFIX,
} from 'CONSTANTS/auaConstants';
import useActions from 'HOOKS/useActions';
import { teamsMeetingActions, teamsMeetingSelector } from 'STORE/teamsMeetingSlice';
import logger from 'SERVICES/logger';
import { userSelector } from 'STORE/userSlice';
import { ITeamsMeetingProps } from 'UTILS/auaInterface';
import './TeamsMeeting.css';

// This component will help us to join teams meeting using composite
const TeamsMeeting = ({
  userName,
  accessToken,
  callState,
  setLoadCaptionsComponent,
  loadCaptionsComponent,
  setCallState,
  showMicrophoneWarningDialog,
  toggleMicrophoneWarningDialog,
}: ITeamsMeetingProps) => {
  // Actions and selectors
  const participantId = useSelector(userSelector.userObjectId);
  const meetingDetails = useSelector(teamsMeetingSelector.meetingDetails);

  // For translations
  const { t } = useTranslation(AUA_LANGUAGE_RESOURCE, LANGUAGE_PREFIX.COMMON);

  // Form factor for composite
  const formFactor = isMobile ? FORM_FACTOR.MOBILE : FORM_FACTOR.DESKTOP;

  // Cleanup call data and closing side panel
  const cleanupCall = async () => {
    logger.info('[TEAMS MEETING PAGE] Cleaning up call data and closing side panel.');
    callAndChatAdapterObj?.offStateChange(onStateChangeHandler);
    setLoadCaptionsComponent(false);
  };

  // Credentials must be memoised
  const credential = useMemo(
    () => new AzureCommunicationTokenCredential(accessToken),
    [accessToken]
  );

  // UserId must be memoised
  const userId = useMemo(() => {
    return {
      communicationUserId: participantId,
    };
  }, [participantId]);

  // Locator must be memoised
  const callAndChatLocator = useMemo(() => {
    return {
      meetingLink: meetingDetails.msTeamsMeetingUrl,
    };
  }, []);

  // For composite to work we need to create adapter
  // Remember locator & credential must be memoised
  const callAndChatAdapterObj = useAzureCommunicationCallWithChatAdapter({
    userId,
    displayName: userName,
    credential,
    locator: callAndChatLocator,
    endpoint: process.env.REACT_APP_CHAT_SERVICE_ENDPOINT,
  });

  const onStateChangeHandler = async (adapterInformationData: any) => {
    if (adapterInformationData?.call) {
      logger.debug('On state change', adapterInformationData?.call);
      logger.debug('Remote participants', adapterInformationData?.call?.remoteParticipants);
      if (adapterInformationData?.call?.state === CALL_ADAPTER_STATE.CONNECTED) {
        // We receive remote participants in adapterInformationData
        // Last participant case
        if (JSON.stringify(adapterInformationData?.call?.remoteParticipants) === '{}') {
          logger.info('All participant left the call so ending the call itself.');
          setCallState(CALL_ADAPTER_STATE.CALL_ENDED);

          await cleanupCall();
          return;
        }
      }
      // This is the case when we click on end call button
      if (adapterInformationData?.call?.state === CALL_STATUS.DISCONNECTING) {
        logger.info('Leaving existing call.');
        setCallState(adapterInformationData?.call?.state);
        await cleanupCall();
        return;
      }

      // Setting call state
      setCallState(adapterInformationData?.call?.state);
    }
  };

  // Subscribing onStateChange of callAdapter
  callAndChatAdapterObj?.onStateChange((adapterInformationData) =>
    onStateChangeHandler(adapterInformationData)
  );

  // Till adapter getting created we will show spinner
  if (!callAndChatAdapterObj) {
    return (
      <Flex fill hAlign="center" vAlign="center">
        <Spinner label={t('SPINNER_LABEL') || undefined} />
      </Flex>
    );
  }

  // This function will help us to open and close the side panel
  const openSidePanel = () => {
    setLoadCaptionsComponent(!loadCaptionsComponent);
  };

  // Injecting custom button inside of composite
  const onFetchCustomButton: CustomCallControlButtonCallback[] = [
    (args: CustomCallControlButtonCallbackArgs): CustomCallControlButtonProps => {
      return {
        placement: 'primary',
        iconName: 'akouoCaptions',
        strings: {
          label: 'Captions',
        },
        disabled: callState !== CALL_ADAPTER_STATE.CONNECTED,
        showLabel: true,
        onItemClick: () => {
          openSidePanel();
        },
        id: `${loadCaptionsComponent ? 'caption-btn-active' : 'caption-btn-inactive'}`,
      };
    },
  ];

  // Once adapter is ready we will show the composite
  if (callAndChatAdapterObj) {
    return (
      <>
        {showMicrophoneWarningDialog ? (
          <InMeetingDialog
            isOpen={showMicrophoneWarningDialog}
            toggleDialog={toggleMicrophoneWarningDialog}
          />
        ) : null}
        <CallWithChatComposite
          adapter={callAndChatAdapterObj}
          fluentTheme={darkTheme}
          formFactor={formFactor}
          options={{
            callControls: {
              onFetchCustomButtonProps: onFetchCustomButton,
              raiseHandButton: true,
              moreButton: false,
            },
          }}
        />
      </>
    );
  }

  return null;
};

export default TeamsMeeting;
