import { Flex, Loader } from '@fluentui/react-northstar';

import 'COMPONENTS/FullScreenLoader/FullScreenLoader.css';

// renders a fullscreen loader with message
const FullScreenLoader = ({ label }: { label: string }) => {
  return (
    <Flex fill column vAlign="center" gap="gap.small" hAlign="center" className="loader">
      <Loader label={label} />
    </Flex>
  );
};

export default FullScreenLoader;
