import { createSlice } from '@reduxjs/toolkit';
import { CAPTIONER_STATUS, CALL_STATUS } from 'CONSTANTS/auaConstants';
interface IInitialState {
  callCaptions: any[];
  serverMessage: object;
  isCaptionerPresent: boolean;
  callStatus: string;
  captionerStatus: string;
}

const initialState: IInitialState = {
  callCaptions: [],
  serverMessage: {},
  isCaptionerPresent: false,
  callStatus: CALL_STATUS.DISCONNECTED,
  captionerStatus: CAPTIONER_STATUS.DISCONNECTED,
};

const captionsSlice = createSlice({
  name: 'captions',
  initialState,
  reducers: {
    setServerMessage: (state, action) => {
      state.serverMessage = action.payload;
    },
    setCaptions: (state, action) => {
      state.callCaptions = [...state.callCaptions, action.payload];
    },
    setIsCaptionerPresent: (state, action) => {
      state.isCaptionerPresent = action.payload;
    },
    setCallStatus: (state, action) => {
      state.callStatus = action.payload;
    },
    setCaptionerStatus: (state, action) => {
      state.captionerStatus = action.payload;
    },
    clearCaptions: (state) => {
      state.callCaptions = [];
    },
  },
});

export const captionsActions = { ...captionsSlice.actions };

export const captionsSelector = {
  serverMessage: (store: any) => store.captions.serverMessage,
  isCaptionerPresent: (store: any) => store.captions.isCaptionerPresent,
  callStatus: (store: any) => store.captions.callStatus,
  captionerStatus: (store: any) => store.captions.captionerStatus,
  allCaptions: (store: any) => store.captions.callCaptions,
  currentCaption: (store: any) =>
    store.captions.callCaptions[store.captions.callCaptions.length - 1],
};

export const callCaptionSelector = (store: any) => store.captions.callCaptions;

export default captionsSlice.reducer;
