// Library import
import { useEffect, useState } from 'react';

// Other import
import { AUA_LANGUAGE_RESOURCE } from 'CONSTANTS/auaConstants';
import languages from 'RESOURCES/languages';
import { addI18nResources } from 'SERVICES/i18n';
import logger from 'SERVICES/logger';

const useAppInitializer = () => {
  //   const { i18n } = useTranslation();
  const [isInitialized, setIsInitialized] = useState(false);

  // Initializing App and Localization Service
  useEffect(() => {
    try {
      setIsInitialized(true);

      // Adding caption resources
      addI18nResources(AUA_LANGUAGE_RESOURCE, languages);
      logger.debug('App initialized');
      setIsInitialized(true);
    } catch (error) {
      logger.error(error);
    }
  }, []);

  return { isInitialized };
};

export default useAppInitializer;
