// Libraries import
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Flex, FlexItem, Text } from '@fluentui/react-northstar';

// Other import
import { AUA_LANGUAGE_RESOURCE, LANGUAGE_PREFIX } from 'CONSTANTS/auaConstants';

import LanguageDropdown from 'FEATURES/languageDropdown/LanguageDropdown';
import { LiveCaption } from 'FEATURES/liveCaption/LiveCaption';

import { teamsMeetingSelector } from 'STORE/teamsMeetingSlice';

// This component will help us to show captions in the meeting
const Captions = ({ spokenLanguageChange, loading, loadCaptionsComponent }: any) => {
  // Selectors
  const meetingLanguages = useSelector(teamsMeetingSelector.meetingSpokenLanguages);
  const { t } = useTranslation(AUA_LANGUAGE_RESOURCE, LANGUAGE_PREFIX.COMMON);

  return (
    <Flex fill column gap="gap.medium">
      <Flex column gap="gap.smaller">
        <Flex gap="gap.small" vAlign="center" space="between">
          <Text content={t('SELECT_LANGUAGE_HEADING')} />
        </Flex>
        <LanguageDropdown
          spokenLanguages={meetingLanguages}
          updateLanguage={spokenLanguageChange}
          loading={loading}
        />
      </Flex>
      <FlexItem flexDirection="column">
        <LiveCaption loading={loading} loadCaptionsComponent={loadCaptionsComponent} />
      </FlexItem>
    </Flex>
  );
};

export default Captions;
