// Libraries import
import { useEffect, useState } from 'react';
import { initializeIcons } from '@fluentui/react';

// Other import
import useAppInitializer from 'HOOKS/useAppInitializer';
import useActions from 'HOOKS/useActions';

import ThemeProvider from 'RESOURCES/theme/ThemeProvider';

import AUARoutes from 'ROUTES/AUARoutes';

import { registerErrorCallback } from 'SERVICES/serverAPI';

import { appActions } from 'STORE/appSlice';

import './App.css';

const App = () => {
  const { isInitialized } = useAppInitializer();

  const setErrorTimestamp = useActions(appActions.setErrorTimestamp);

  // stores error DateTime
  const [errorValue, setErrorValue] = useState<number | undefined>(undefined);

  // error callback
  const errorCallback = () => {
    setErrorValue(Date.now);
  };

  // set error timeStamp when error value is changed i.e., when error occured
  useEffect(() => {
    if (errorValue && setErrorTimestamp) {
      // set error timeStamp in store
      setErrorTimestamp();
    }
  }, [errorValue]);

  registerErrorCallback(errorCallback);

  initializeIcons();

  if (!isInitialized) return null;

  return (
    <ThemeProvider>
      <AUARoutes />
    </ThemeProvider>
  );
};

export default App;
