// Libraries import
import { useTranslation } from 'react-i18next';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { FluentThemeProvider, darkTheme } from '@azure/communication-react';

// Other import
import FullScreenWarning from 'COMPONENTS/FullScreenWarning/FullScreenWarning';
import { AUA_LANGUAGE_RESOURCE, LANGUAGE_PREFIX } from 'CONSTANTS/auaConstants';
import Home from 'FEATURES/Home/Home';

const AUARoutes = () => {
  const { t } = useTranslation(AUA_LANGUAGE_RESOURCE, LANGUAGE_PREFIX.COMMON);

  return (
    <FluentThemeProvider fluentTheme={darkTheme}>
      <BrowserRouter>
        <Routes>
          <Route path="/:meetingId" element={<Home />} />
          <Route path="*" element={<FullScreenWarning label={t('PAGE_NOT_FOUND_404')} />} />
        </Routes>
      </BrowserRouter>
    </FluentThemeProvider>
  );
};

export default AUARoutes;
