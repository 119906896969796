// Libraries import
import { useEffect, useMemo, useState } from 'react';
import { browserName, isMobile, isAndroid } from 'react-device-detect';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { CallEndIcon, Flex, Text } from '@fluentui/react-northstar';
import {
  Dialog,
  DialogFooter,
  MessageBar,
  MessageBarType,
  Spinner,
  Stack,
  StackItem,
} from '@fluentui/react';
import { TextField } from '@fluentui/react/lib/TextField';
import { PrimaryButton } from '@fluentui/react/lib/Button';

// Other imports
import {
  ANDROID_SUPPORTED_BROWSER,
  AUA_LANGUAGE_RESOURCE,
  BROWSER_SUPPORTED,
  ENTER_KEY_CODE,
  IOS_SUPPORTED_BROWSER,
  LANGUAGE_PREFIX,
} from 'CONSTANTS/auaConstants';
import { ERROR } from 'CONSTANTS/apiConstants';

import Dashboard from 'FEATURES/dashboard/Dashboard';

import useActions from 'HOOKS/useActions';

import { getMeetingDetails, createUserAndGetDetails } from 'SERVICES/api';
import { useTranslation } from 'SERVICES/i18n';
import logger from 'SERVICES/logger';

import { appSelector } from 'STORE/appSlice';
import { captionsActions } from 'STORE/captionsSlice';
import { teamsMeetingActions, teamsMeetingSelector } from 'STORE/teamsMeetingSlice';
import { userActions } from 'STORE/userSlice';

// Home component
const Home = () => {
  // For translations
  const { t } = useTranslation(AUA_LANGUAGE_RESOURCE, LANGUAGE_PREFIX.COMMON);

  // Actions
  const { setMeetingDetails } = useActions(teamsMeetingActions);
  const { setUserId, setPubSubDetails, setUserObjectId } = useActions(userActions);
  const { setIsCaptionerPresent } = useActions(captionsActions);

  // Selectors
  const errorTimestamp = useSelector(appSelector.errorTimestamp);
  const callEnded = useSelector(teamsMeetingSelector.callEnded);

  // States
  const [userName, setUserName] = useState('');
  const [hideDialog, setHideDialog] = useState(false);
  const [meetingDataReceived, setMeetingDataReceived] = useState(false);
  const [loadTeamsMeetingComponent, setLoadTeamsMeetingComponent] = useState(false);
  const [apiError, setApiError] = useState<any | null>('');
  const [socketClientError, setSocketClientError] = useState(false);
  const [accessToken, setAccessToken] = useState('');

  // Taking from the routes
  const { meetingId } = useParams();

  const dialogContentProps = {
    title: t('JOIN_MEETING_TITLE'),
    showCloseButton: false,
  };

  const modalProps = useMemo(
    () => ({
      isBlocking: true,
      styles: { main: { maxWidth: 550 } },
    }),
    []
  );

  const closeDialog = () => setHideDialog(true);

  // On click of join button
  const onDialogButtonClicked = async () => {
    const name = userName.trim();
    if (name.length > 0 && meetingId) {
      closeDialog();
      try {
        // Calling API for creating new user and required details
        const userDetails = await createUserAndGetDetails(name, meetingId);
        setUserId(userDetails.userId);
        setAccessToken(userDetails.userToken);
        setPubSubDetails(userDetails.pubsubClientDetails);
        setUserObjectId(userDetails.userObjectId);

        // Show the meeting component
        setLoadTeamsMeetingComponent(true);
      } catch (error: any) {
        logger.error('Error while calling API for user creation', error);
        setMeetingDataReceived(false);

        setApiError(t('PRE_MEETING_START_ERROR'));
      }
    }
  };

  // Textvalue change for name
  const onNameChange = (_event: any, newValue: any) => {
    setUserName(newValue.replace(/[^A-Z0-9 ]+/i, ''));
  };

  // Checking for supported browser
  const deviceDetails = () => {
    if (isMobile) {
      return isAndroid ? ANDROID_SUPPORTED_BROWSER : IOS_SUPPORTED_BROWSER;
    }
    return BROWSER_SUPPORTED;
  };

  // Main useEffect
  useEffect(() => {
    // Using IIFE to call the API asynchronously
    const callApis = async () => {
      try {
        // First we are calling getMeeting API
        // If feature is not enabled then we are not creating user and rest of the things
        if (meetingId) {
          const meetingDetails = await getMeetingDetails(meetingId);
          setMeetingDetails(meetingDetails);
          setIsCaptionerPresent(meetingDetails.isCaptionerConnected);
          setMeetingDataReceived(true);
        }
      } catch (error: any) {
        logger.error('Error while calling API', error);
        setApiError(
          error.code === ERROR.FEATURE_NOT_ENABLED
            ? t('MEETING_UNAVAILABLE_UA_ERROR')
            : t('PRE_MEETING_START_ERROR')
        );
      }
    };

    // Fetching meeting data before showing the component
    callApis();
  }, []);

  // To handle application level error
  useEffect(() => {
    if (errorTimestamp) {
      setApiError(t('GENERIC_ERROR'));
    }
  }, [errorTimestamp]);

  // Browser not supported view
  if (!deviceDetails().includes(browserName)) {
    return (
      <Stack verticalFill verticalAlign="center" horizontalAlign="center">
        <StackItem>
          <Text size="small" align="center">
            {isMobile ? t('UNSUPPORTED_MOBILE_BROWSER') : t('UNSUPPORTED_DESKTOP_BROWSER')}
          </Text>
        </StackItem>
        <StackItem>
          <ul>
            {deviceDetails().map((element, key) => {
              return (
                <>
                  <li>{`${element}`}</li>
                </>
              );
            })}
          </ul>
        </StackItem>
      </Stack>
    );
  }

  // callEnded
  if (callEnded) {
    return (
      <Flex fill hAlign="center" vAlign="center" gap="gap.medium">
        <CallEndIcon size="larger" className="call-end-icon" />
        <Text size="large" content={t('MEETING_ENDED')} />
      </Flex>
    );
  }

  // If pubsub connection fails
  if (socketClientError) {
    return (
      <Flex fill hAlign="center" vAlign="center" gap="gap.medium">
        <CallEndIcon size="larger" className="call-end-icon" />
        <Text size="large" content={t('WEB_SOCKET_ERROR')} />
      </Flex>
    );
  }

  if (meetingDataReceived) {
    if (loadTeamsMeetingComponent) {
      return (
        <Dashboard
          userName={userName}
          accessToken={accessToken}
          setLoadTeamsMeetingComponent={setLoadTeamsMeetingComponent}
          setSocketClientError={setSocketClientError}
        />
      );
    }

    // Show spinner while fetching meeting data
    return hideDialog ? (
      <Flex fill hAlign="center" vAlign="center">
        <Spinner label={t('SPINNER_LABEL') || ''} />
      </Flex>
    ) : (
      <Dialog modalProps={modalProps} dialogContentProps={dialogContentProps} hidden={hideDialog}>
        <TextField
          placeholder={t('ENTER_NAME_PLACEHOLDER') || ''}
          onChange={onNameChange}
          value={userName}
          onKeyDown={(e) => {
            if (e.key === ENTER_KEY_CODE) {
              e.preventDefault();
              onDialogButtonClicked();
            }
          }}
        />
        <DialogFooter>
          <PrimaryButton
            onClick={() => onDialogButtonClicked()}
            text={t('JOIN_BUTTON_TEXT') || ''}
            disabled={userName?.trim().length < 1}
          />
        </DialogFooter>
      </Dialog>
    );
  }

  return apiError ? (
    <MessageBar messageBarType={MessageBarType.error}>{apiError}</MessageBar>
  ) : (
    <Flex fill hAlign="center" vAlign="center">
      <Spinner label={t('LOADING') || ''} />
    </Flex>
  );
};

export default Home;
