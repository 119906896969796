// libraries import
import { useTranslation } from 'react-i18next';
import {
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogBody,
  DialogContent,
  Button,
  FluentProvider,
  webDarkTheme,
  DialogActions,
} from '@fluentui/react-components';
import { Flex, Text } from '@fluentui/react-northstar';

// constants import
import { AUA_LANGUAGE_RESOURCE, LANGUAGE_PREFIX } from 'CONSTANTS/auaConstants';

// utils import
import { IInMeetingDialogProps } from 'UTILS/auaInterface';

// assets import
import AkouoMiniLogo from 'RESOURCES/assets/akouo-mini-logo-white.svg';

// css import
import './InMeetingDialog.css';

const InMeetingDialog = ({ isOpen, toggleDialog }: IInMeetingDialogProps) => {
  const { t } = useTranslation(AUA_LANGUAGE_RESOURCE, LANGUAGE_PREFIX.INMEETING);

  return (
    <FluentProvider theme={webDarkTheme}>
      <Dialog modalType="modal" open={isOpen}>
        <DialogSurface>
          <DialogBody>
            <DialogTitle>
              <Flex gap="gap.small" vAlign="center">
                <img src={AkouoMiniLogo} alt="" id="inMeetingDialogLogo" />
                <Text
                  size="larger"
                  weight="bold"
                  content={t('MICROPHONE_WARNING_TITLE')}
                  className="microphone-warning-title"
                />
              </Flex>
            </DialogTitle>
            <DialogContent>{t('MICROPHONE_WARNING_TEXT')}</DialogContent>
            <DialogActions>
              <DialogTrigger>
                <Button appearance="secondary" onClick={toggleDialog}>
                  {t('CLOSE_BUTTON_TEXT')}
                </Button>
              </DialogTrigger>
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>
    </FluentProvider>
  );
};

export default InMeetingDialog;
