import { createSlice } from '@reduxjs/toolkit';

interface IInitialState {
  meetingLanguages: string[];
  meetingSpokenLanguages: string[];
  meetingDetails: object | undefined;
  callState?: undefined;
  callEnded?: undefined;
}

const initialState: IInitialState = {
  meetingDetails: undefined,
  meetingLanguages: [],
  meetingSpokenLanguages: [],
  callState: undefined,
  callEnded: undefined,
};

const teamsMeetingSlice = createSlice({
  initialState,
  name: 'teamsMeeting',
  reducers: {
    setMeetingDetails: (state, action) => {
      state.meetingDetails = action.payload;
      state.meetingLanguages = action.payload?.languages;
      state.meetingSpokenLanguages = action.payload?.spokenLanguages;
    },
    setMeetingLanguages: (state, action) => {
      state.meetingLanguages = action.payload;
    },
    setCallState(state, action) {
      state.callState = action.payload;
    },
    setCallEnded(state, action) {
      state.callEnded = action.payload;
    },
  },
});

export default teamsMeetingSlice.reducer;

export const teamsMeetingActions = { ...teamsMeetingSlice.actions };

export const teamsMeetingSelector = {
  meetingDetails: (store: any) => store.teamsMeeting.meetingDetails,
  meetingLanguages: (store: any) => store.teamsMeeting.meetingLanguages,
  meetingSpokenLanguages: (store: any) => store.teamsMeeting.meetingSpokenLanguages,
  callState: (store: any) => store.teamsMeeting.callState,
  callEnded: (store: any) => store.teamsMeeting.callEnded,
};
