// Library import
import { ReactNode } from 'react';
import { Provider, teamsDarkTheme } from '@fluentui/react-northstar';

// Other import
import Snackbar from 'COMPONENTS/snackbar/Snackbar';
import 'RESOURCES/theme/ThemeProvider.css';

// provides basic theme for Fluent UI components
const ThemeProvider = ({ children }: { children: ReactNode }) => {
  return (
    <Provider theme={teamsDarkTheme} className="theme-wrapper">
      {children}
      <Snackbar />
    </Provider>
  );
};

export default ThemeProvider;
