import serverAPI from 'SERVICES/serverAPI';
import { API_CONTEXT_AUA, API_ENDPOINTS } from 'CONSTANTS/apiConstants';
import { IPutMeetingData } from 'UTILS/apiInterface';

/**
 * Fetch Meeting details from backend.
 * @param {String} meetingId: Teams Meeting Id
 * @param {?String} code: Authentication code received from auth code flow
 * @returns response data
 */
export const getMeetingDetails = async (meetingId: string) => {
  const url = `${API_ENDPOINTS.MEETING}/${meetingId}/${API_CONTEXT_AUA}`;
  const response = await serverAPI.get(url);
  return response.data;
};

/**
 *This function will help us to create user on the fly
 * @returns newly created user id
 */
export const createUserAndGetDetails = async (userName: string, meetingId: string) => {
  const url = API_ENDPOINTS.CREATE_USER;
  const response = await serverAPI.post(url, { userName, meetingId });
  return response.data;
};

/**
 * This flow will used to update language in PowerApps
 * @param meetingData To update user spoken language
 * @returns status
 */
export const putMeeting = async (meetingData: IPutMeetingData) => {
  const url = `${API_ENDPOINTS.MEETING}/${API_CONTEXT_AUA}`;
  return serverAPI.post(url, meetingData);
};
