import { createSlice } from '@reduxjs/toolkit';
import PubSubClient from 'SERVICES/PubSubClient';

interface IInitialState {
  userId: string | null;
  wsClientObj: PubSubClient | null;
  selectedSpokenLanguages: string | null;
  pubsubDetails: any;
  userObjectId: string | null;
}

const initialState: IInitialState = {
  userId: null,
  wsClientObj: null,
  selectedSpokenLanguages: null,
  pubsubDetails: null,
  userObjectId: null,
};

const userSlice = createSlice({
  initialState,
  name: 'user',
  reducers: {
    setSelectedSpokenLanguages: (state, action) => {
      state.selectedSpokenLanguages = action.payload;
    },
    setUserId: (state, action) => {
      state.userId = action.payload;
    },
    setWsClient(state, action) {
      state.wsClientObj = action.payload;
    },
    setPubSubDetails(state, action) {
      state.pubsubDetails = action.payload;
    },
    setUserObjectId(state, action) {
      state.userObjectId = action.payload;
    },
  },
});

export default userSlice.reducer;

export const userActions = { ...userSlice.actions };

export const userSelector = {
  selectedSpokenLanguages: (store: any) => store.user.selectedSpokenLanguages,
  wsClientObj: (store: any) => store.user.wsClientObj,
  pubSubDetails: (store: any) => store.user.pubsubDetails,
  userId: (store: any) => store.user.userId,
  userObjectId: (store: any) => store.user.userObjectId,
};
