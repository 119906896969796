import ar from 'RESOURCES/languages/ar.json';
import cs from 'RESOURCES/languages/cs.json';
import da from 'RESOURCES/languages/da.json';
import de from 'RESOURCES/languages/de.json';
import el from 'RESOURCES/languages/el.json';
import en from 'RESOURCES/languages/en.json';
import es from 'RESOURCES/languages/es.json';
import fi from 'RESOURCES/languages/fi.json';
import fr from 'RESOURCES/languages/fr.json';
import he from 'RESOURCES/languages/he.json';
import hi from 'RESOURCES/languages/hi.json';
import hu from 'RESOURCES/languages/hu.json';
import it from 'RESOURCES/languages/it.json';
import ja from 'RESOURCES/languages/ja.json';
import ko from 'RESOURCES/languages/ko.json';
import nl from 'RESOURCES/languages/nl.json';
import no from 'RESOURCES/languages/no.json';
import pl from 'RESOURCES/languages/pl.json';
import pt from 'RESOURCES/languages/pt.json';
import ro from 'RESOURCES/languages/ro.json';
import ru from 'RESOURCES/languages/ru.json';
import sk from 'RESOURCES/languages/sk.json';
import sv from 'RESOURCES/languages/sv.json';
import th from 'RESOURCES/languages/th.json';
import tr from 'RESOURCES/languages/tr.json';
import uk from 'RESOURCES/languages/uk.json';
import zh from 'RESOURCES/languages/zh.json';

const languages = {
  ar,
  cs,
  da,
  de,
  el,
  en,
  es,
  fi,
  fr,
  he,
  hi,
  hu,
  it,
  ja,
  ko,
  nl,
  no,
  pl,
  pt,
  ro,
  ru,
  sk,
  sv,
  th,
  tr,
  uk,
  zh,
};

export default languages;
