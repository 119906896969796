// Libraries import
import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Flex, Text } from '@fluentui/react-northstar';

// Other import
import FullScreenLoader from 'COMPONENTS/FullScreenLoader/FullScreenLoader';
import FullScreenWarning from 'COMPONENTS/FullScreenWarning/FullScreenWarning';

import {
  LANGUAGE_CAPTION_CODE,
  LANGUAGE_PREFIX,
  SCROLL_BEHAVIOUR,
  AUA_LANGUAGE_RESOURCE,
  CAPTIONER_STATUS,
  CALL_STATUS,
} from 'CONSTANTS/auaConstants';

import useActions from 'HOOKS/useActions';

import { captionsActions, captionsSelector } from 'STORE/captionsSlice';
import { userSelector } from 'STORE/userSlice';

import './LiveCaption.css';

// This component will help us to show live captions in the meeting
export const LiveCaption = ({
  loading,
  loadCaptionsComponent,
}: {
  loading: boolean;
  loadCaptionsComponent: boolean;
}) => {
  /**
   * const caption = [
   *  {
   *    id: messageId,
   *    name: captionData.speaker.displayName,
   *    text: captionData.text
   *  }
   * ]
   */

  // Actions and selectors
  const { t } = useTranslation(AUA_LANGUAGE_RESOURCE, LANGUAGE_PREFIX.COMMON);
  const { clearCaptions } = useActions(captionsActions);
  const selectedLanguage = useSelector(userSelector.selectedSpokenLanguages);
  const currentCaption = useSelector(captionsSelector.currentCaption);
  // const allCaptions = useSelector(captionsSelector.allCaptions);
  const callStatus = useSelector(captionsSelector.callStatus);
  const captionerStatus = useSelector(captionsSelector.captionerStatus);
  const serverMessage = useSelector(captionsSelector.serverMessage);
  const isCaptionerPresent = useSelector(captionsSelector.isCaptionerPresent);

  // Refs
  const previousSpeker = useRef(null);
  const captionAreaRef: any = useRef(null);
  const captions: any = useRef([]);

  // State
  const [renderComponent, setRenderComponent] = useState(Date.now());

  // when captionsData changes the caption area will be scrolled to bottom
  useEffect(() => {
    // set scroll to bottom
    if (captionAreaRef.current) {
      captionAreaRef.current.scrollTo({
        top: captionAreaRef.current.scrollHeight,
        behavior: SCROLL_BEHAVIOUR,
      });
    }
  }, [renderComponent, loading]);

  // useEffect(() => {
  //   if (loadCaptionsComponent && allCaptions.length > 1) {
  //     panelOpenRef.current = false;

  //     // Clearing all stuff
  //     captions.current = [];
  //     clearCaptions();
  //     previousSpeker.current = null;

  //     let captionLanguage = selectedLanguage.code.split('-')[0];
  //     captionLanguage =
  //       captionLanguage === LANGUAGE_CAPTION_CODE.ZH ? LANGUAGE_CAPTION_CODE.LZH : captionLanguage;

  //     allCaptions.forEach((captionElement: any, index: number) => {
  //       const latestCaption = captionElement.captions[captionLanguage];
  //       previousSpeker.current = index === 0 ? captionElement.speaker : previousSpeker.current;
  //       const isSpeakerChanged = previousSpeker.current !== captionElement.speaker || index === 0;
  //       captions.current.push(
  //         <Flex column gap="gap.smaller" key={Math.random().toString(16)}>
  //           <Flex vAlign="start" gap="gap.smaller">
  //             <Flex column gap="gap.smaller">
  //               {isSpeakerChanged && (
  //                 <Text weight="semibold" color="brand" content={captionElement.speaker} />
  //               )}
  //               <Text content={latestCaption} />
  //             </Flex>
  //           </Flex>
  //         </Flex>
  //       );
  //       previousSpeker.current = captionElement.speaker;
  //     });
  //     panelOpenRef.current = true;
  //     setRenderComponent(Date.now());
  //   }
  // }, [loadCaptionsComponent]);

  // When new caption is received
  useEffect(() => {
    if (currentCaption && selectedLanguage?.code) {
      let captionLanguage = selectedLanguage.code.split('-')[0];
      captionLanguage =
        captionLanguage === LANGUAGE_CAPTION_CODE.ZH ? LANGUAGE_CAPTION_CODE.LZH : captionLanguage;
      const latestCaption = currentCaption.captions[captionLanguage];
      const isSpeakerChanged = previousSpeker.current !== currentCaption.speaker;
      captions.current.push(
        <Flex column gap="gap.smaller" key={Math.random().toString(16)}>
          <Flex vAlign="start" gap="gap.smaller">
            <Flex column gap="gap.smaller">
              {isSpeakerChanged && (
                <Text weight="semibold" color="brand" content={currentCaption.speaker} />
              )}
              <Text content={latestCaption} />
            </Flex>
          </Flex>
        </Flex>
      );
      previousSpeker.current = currentCaption.speaker;
      setRenderComponent(Date.now());
    }
  }, [currentCaption]);

  // When captioner status changes
  useEffect(() => {
    setRenderComponent(Date.now());
  }, [callStatus, serverMessage, captionerStatus]);

  // Render function
  const render = () => {
    // render loader when captioner statue is connecting
    if (callStatus === CALL_STATUS.CONNECTING)
      return <FullScreenLoader label={t('CAPTIONER_JOINING')} />;

    // render warning when Captioner is not present in meeting
    if (!isCaptionerPresent) {
      // clearing captions array when captioner disconnectss
      if (captions.current?.length !== 0) {
        captions.current = [];
        clearCaptions();
        previousSpeker.current = null;
      }
      return <FullScreenWarning label={t('CAPTIONER_DISCONNECTED')} />;
    }

    // captions are off and captioner is connected in the meeting
    if (captionerStatus === CAPTIONER_STATUS.DISCONNECTED) {
      return <Text align="center" size="medium" content={t('CAPTIONS_NOT_CONNECTED')} />;
    }

    // render loader when language is changed
    if (loading) return <FullScreenLoader label={t('SWITCHING_LANGUAGE')} />;

    // render message when captioner is connected but no captions is sent by captioner
    if (captions.current?.length === 0 && isCaptionerPresent)
      return <Text size="medium" align="center" content={t('CAPTIONER_JOINED')} />;

    // Captions are present
    return captions.current;
  };

  return (
    <Flex fill column className="caption-area" gap="gap.smaller" ref={captionAreaRef}>
      {render()}
    </Flex>
  );
};
